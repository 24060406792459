import { graphql, Link } from "gatsby";
import React, { PropsWithChildren, ReactElement } from "react";
import Helmet from "../../components/Helmet";
import { getSeoDescription, getSeoTitle, getSeoKeywords } from "../../shared/helpers";
import styles from "./404.module.scss";

const NotFoundPage = (props: PropsWithChildren<any>): ReactElement => {

    const data = JSON.parse(props.data.allPrismic404.edges[0].node.dataString);

    return (
        <div className={styles.notFoundPage}
            style={{
                backgroundImage: `url(${data?.background_img?.url})` 
            }}
        >

            <Helmet
                title={getSeoTitle(data, "Not found")}
                description={getSeoDescription(data, "Page not found")}    
                keywords={getSeoKeywords(data, "")}        
            />

            <h1 className={styles.pageTitle}>404</h1>

            <h2 className={styles.pageSubtitle}>{data?.error_title[0]?.text}</h2>

            <p className={styles.pageParagraph}>{data?.error_description[0]?.text}</p>

            <Link to="/" className={styles.backHomeLink}> HOMEPAGE </Link>

        </div>
    );
}


export const query = graphql`
    query {
        allPrismic404 {
            edges {
                node {
                    dataString
                }
            }
        }
    }
`;
export default NotFoundPage;